import React, { useEffect, useRef, useState } from 'react'
import classnames from 'classnames/bind'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as THREE from 'three'
import CDGraphics from 'cdgraphics'

import styles from './Index.module.scss'

import Player from '@components/Player'
import layoutStyles from '@components/Layout/Layout.module.scss'
import PageTransition from '@components/PageTransition'
import SEO from '@components/seo'
import isiOS from '@utils/isiOS'
import { clamp } from '@utils/numbers'

import NextPage from './chapter-07'

const cx = classnames.bind({ ...styles, ...layoutStyles })

const VIDEO_01 = '/k/chapter-06/vids/01.mp4'
const AUDIOURL = '/k/chapter-06/index.mp3'
const CDGURL = '/k/chapter-06/index.cdg'

const initThreeScene = (t3d) => {
  if (!t3d.current) {
    return
  }
  const vid01 = new THREE.VideoTexture(t3d.current.ch2.video01)
  vid01.minFilter = THREE.LinearFilter
  vid01.magFilter = THREE.LinearFilter
  vid01.format = THREE.RGBFormat

  const vid01Geo = new THREE.BoxGeometry(1, 1, 1)
  const vid01Mat = new THREE.MeshBasicMaterial({ map: vid01, side: THREE.DoubleSide })
  const vid01Mesh = new THREE.Mesh(vid01Geo, vid01Mat)
  vid01Mesh.name = 'Chapter 2 - Video 1'
  vid01Mesh.scale.set(0, 0, 0)
  vid01Mesh.rotation.x = 0.1
  t3d.current.vid01Mesh = vid01Mesh
  t3d.current.scene?.add(vid01Mesh)
}

const IndexPage = (
  {
    playProp,
    location,
    showBorders,
    kanvasRef,
    t3d,
    setPageNavigate,
    isHover,
    setIsHover,
    showUI,
    setShowUI,
    setIsPlaying,
    mouseTimeout,
  },
  rest
) => {
  const indexRef = useRef(null)
  const audioRef = useRef(null)
  const cdgRef = useRef(null)

  const [isNextPage, setIsNextPage] = useState(false)

  const handleTick = () => {
    if (!audioRef.current) {
      return
    }
    const { currentTime, duration } = audioRef.current.audio.current

    t3d.current.vid01Mesh.rotation.y = (currentTime / 4) * (Math.PI / 2)
    if (currentTime <= 32) {
      const val = clamp((currentTime / 24) * 4, 0, 4)
      t3d.current.vid01Mesh.scale.set(val, val, val)
    }
    if (currentTime > 32 && currentTime < 104) {
      const val = 4
      t3d.current.vid01Mesh.scale.set(val, val, val)
    }
    if (currentTime >= 104 && currentTime <= 114) {
      const val = 4 - clamp(((currentTime - 104) / 10) * 4, 0, 4)
      t3d.current.vid01Mesh.scale.set(val, val, val)
    }

    cdgRef.current.render(audioRef.current.audio.current.currentTime)
  }

  // methods for render loop
  const play = () => {
    setShowUI(false)
    setIsPlaying(true)
    t3d.current.ch2.video01.play()
    window.addEventListener('animation-tick', handleTick, false)
  }
  const pause = () => {
    setShowUI(true)
    setIsPlaying(false)
    if (mouseTimeout.current) {
      window.clearTimeout(mouseTimeout.current)
    }
    window.addEventListener('animation-tick', handleTick, false)
  }
  const end = () => {
    pause()
    while (t3d.current.scene.children.length > 0) {
      t3d.current.scene.remove(t3d.current.scene.children[0])
    }
    history.pushState({}, null, '/chapter-07/')
    setIsNextPage(true)
    // setPageNavigate('/chapter-07/?play')
  }

  const handleMouseEnter = () => {
    setIsHover(true)
  }
  const handleMouseLeave = () => {
    setIsHover(false)
  }

  useEffect(() => {
    if (!kanvasRef.current) {
      return
    }

    console.log('𝍌 page mounted')
    setIsPlaying(false)

    /* Videos */
    if (!t3d.current.ch2) {
      t3d.current.ch2 = {}
    }
    t3d.current.ch2.video01 = document.createElement('video')
    t3d.current.ch2.video01.src = VIDEO_01
    t3d.current.ch2.video01.setAttribute('playsinline', '')
    t3d.current.ch2.video01.setAttribute('webkit-playsinline', '')
    t3d.current.ch2.video01.setAttribute('autoplay', '')
    t3d.current.ch2.video01.setAttribute('loop', '')
    t3d.current.ch2.video01.load()

    cdgRef.current = new CDGraphics(kanvasRef.current, {
      forceKey: true,
      onBackgroundChange: (color) => {
        console.log('onBackgroundChange', color)
      },
    })

    /** CDG **/
    if (kanvasRef.current) {
      // link to audio events (depending on your app, not all are strictly necessary)
      audioRef.current.audio.current.addEventListener('play', () => {
        // t3d.current.ch2.video01.play()
        play()
      })
      audioRef.current.audio.current.addEventListener('pause', pause)
      audioRef.current.audio.current.addEventListener('ended', end)
      audioRef.current.audio.current.addEventListener('seeked', () =>
        cdgRef.current.render(audioRef.current.audio.current.currentTime)
      )

      // download and load cdg file
      fetch(CDGURL)
        .then((response) => response.arrayBuffer())
        .then((buffer) => {
          // arrayBuffer to Uint8Array
          cdgRef.current.load(new Uint8Array(buffer))

          // start loading audio
          audioRef.current.audio.current.src = AUDIOURL
          if (window.location.search.indexOf('play') > -1 || playProp) {
            audioRef.current.audio.current.autoplay = true
          }

          initThreeScene(t3d)
        })
    }

    /** CleanUp **/
    return () => {
      console.log('page cleanup')
      window.removeEventListener('animation-tick', handleTick)
      if (audioRef.current) {
        audioRef.current.audio.current.removeEventListener('play', play)
        audioRef.current.audio.current.removeEventListener('pause', pause)
        audioRef.current.audio.current.removeEventListener('ended', pause)
        audioRef.current.audio.current.removeEventListener('seeked', () =>
          cdgRef.current.render(audioRef.current.audio.current.currentTime)
        )
      }
      while (t3d.current.scene.children.length > 0) {
        t3d.current.scene.remove(t3d.current.scene.children[0])
      }
    }
  }, [])

  if (isNextPage) {
    return (
      <NextPage
        playProp={true}
        location={location}
        kanvasRef={kanvasRef}
        t3d={t3d}
        setPageNavigate={setPageNavigate}
        isHover={isHover}
        setIsHover={setIsHover}
        showUI={showUI}
        setIsPlaying={setIsPlaying}
        mouseTimeout={mouseTimeout}
        {...rest}
      />
    )
  }

  return (
    <PageTransition location={location} pagePath="/">
      <div
        ref={indexRef}
        className={cx('index-page', 'chapter-06', {
          'page-borders': showBorders,
          'mobile-safari': isiOS(),
        })}
      >
        <SEO title="Snake Heist" />
        {/* <audio className={cx('audio')} ref={audioRef} controls /> */}
        <Player
          ref={audioRef}
          isHover={isHover}
          showUI={showUI}
          setShowUI={setShowUI}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      </div>
    </PageTransition>
  )
}

IndexPage.propTypes = {
  t3d: PropTypes.object,
  kanvasRef: PropTypes.object,
  location: PropTypes.object,
  FTUI: PropTypes.bool,
  showBorders: PropTypes.bool,
  setHeroRef: PropTypes.func,
  setPageNavigate: PropTypes.func,
  showUI: PropTypes.bool,
  setShowUI: PropTypes.func,
  setIsPlaying: PropTypes.func,
  mouseTimeout: PropTypes.object,
}

const mapStateToProps = ({ showBorders, FTUI, showUI, isHover }) => {
  return { showBorders, FTUI, showUI, isHover }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPageNavigate: (target) => dispatch({ type: `SETPAGETRANSITIONURLTARGET`, payload: target }),
    setHeroRef: (target) => dispatch({ type: `SETHEROREF`, payload: target }),
    setShowUI: (target) => dispatch({ type: `SETSHOWUI`, payload: target }),
    setIsHover: (target) => dispatch({ type: `SETISHOVER`, payload: target }),
    setIsPlaying: (target) => dispatch({ type: `SETISPLAYING`, payload: target }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage)
